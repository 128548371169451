import React from 'react';
import Layout from '../../components/layout';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';

import './picks.scss';

const Page = () => (
  <Layout>
    <Helmet>
      <body page-name="page-picks" />
    </Helmet>

    <main>
      <section>
        <h1>
          <Link to="/picks">picks</Link> / books
        </h1>

        <p>
          insightful volumes which not only look good on my shelves, but that I
          find myself returning to time and time again
        </p>

        <hr />

        <dl>
          {books.map(([title, author, url, desc]) => (
            <React.Fragment key={title}>
              <h3>
                {title}{' '}
                <small>
                  (<a href={url}>link</a>)
                </small>
              </h3>
              <p className="author">{author}</p>
              {desc ? <p>{desc}</p> : null}
            </React.Fragment>
          ))}
        </dl>
      </section>
    </main>
  </Layout>
);

//prettier-ignore
const books = [
  [
    'The Visual Display of Quantitative Information',
    'Edward R. Tufte',
    'https://www.edwardtufte.com/tufte/books_vdqi',
    <>… and all of Tufte's other works</>
  ],
  [
    'The Elements of Typographic Style',
    'Robert Bringhurst',
    'https://www.amazon.com/Elements-Typographic-Style-Version-Anniversary/dp/088179211X/',
  ],
  [
    'The Demon-Haunted World',
    'Carl Sagan',
    'https://www.amazon.com/Demon-Haunted-World-Science-Candle-Dark/dp/0345409469',
  ],
  [
    'Quantum Mechanics: The Theoretical Minimum',
    'Leonard Susskind & Art Friedman',
    'https://www.amazon.com/Quantum-Mechanics-Theoretical-Leonard-Susskind/dp/0465062903',
  ],
  [
    'The Book',
    'Keith Houston',
    'https://www.amazon.com/Book-Cover-Cover-Exploration-Powerful/dp/0393244792'
  ],
  [
    'Thinking with Type',
    'Ellen Lupton',
    'https://www.amazon.com/Thinking-Type-2nd-revised-expanded/dp/1568989695',
  ],
  [
    'Just My Type',
    'Simon Garfield',
    'https://www.amazon.com/Just-My-Type-About-Fonts/dp/1592407463'
  ]
];

export default Page;
